import React, { useEffect } from 'react';
import Navbar from '../../Components/Navbar/Navbar';
import {
    HeaderAvatar,
    HeaderAvatarMobile,
    Brand1,
    Brand2,
    Brand3,
    Brand5,
    ThirdSectionImg,
    FourthSectionImg,
    FifthSectionImg,
    Sass,
    Agency,
    Ecom,
    Penny,
    Celo,
    Ghana
} from '../../Utils/values';
import { backToTop } from '../../Utils/methods';
import $ from 'jquery';
import './index.scss';
import './animate.scss';
import useInView from 'react-cool-inview';

const Home = () => {
    useEffect(() => {
        backToTop();
    }, []);

    const { observe } = useInView({
        onEnter: ({ scrollDirection, entry, observe, unobserve }) => {
            $('.third__section__header').addClass('slideDown');
            $('.third__section__secondary').addClass('fadeIn');
            $('.third__section__link').addClass('slideUp');
        }
    });
    const { observe: observe1 } = useInView({
        onEnter: ({ scrollDirection, entry, observe, unobserve }) => {
            $('.fifth__section__header').addClass('slideDown');
            $('.fifth__section__secondary').addClass('fadeIn');
            $('.fifth__section__link').addClass('slideUp');
        }
    });
    const { observe: observe2 } = useInView({
        onEnter: ({ scrollDirection, entry, observe, unobserve }) => {
            $('.fourth__section__header').addClass('slideDown');
            $('.fourth__section__secondary').addClass('fadeIn');
            $('.fourth__section__link').addClass('slideUp');
        }
    });
    const { observe: observe3 } = useInView({
        onEnter: ({ scrollDirection, entry, observe, unobserve }) => {
            $('.seventh__section__icon').addClass('fadeIn');
        }
    });
    return (
        <>
            <Navbar />
            <br />
            <section id="headerSection" className="">
                <div className="container header__section px-5">
                    <div className="row ">
                        <div className="col-12 col-lg-6 ">
                            <div className="header__main__text">
                                Automate code production in minutes
                            </div>
                            <div className="header__secondary__text mt-3">
                                Gain hours of work on code creation. HazeGen
                                gives you the freedom to generate social &
                                advertising codes online in minutes.
                            </div>
                            <div className="mt-5 header__button__section">
                                <button className="btn btn-lg btn-primary header__button">
                                    Get started now
                                </button>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 header__avatar__container header__avatar__mobile">
                            <img
                                src={HeaderAvatarMobile}
                                alt="header avatar"
                                className="header__avatar"
                            />
                        </div>
                        <div className="col-12 col-lg-6 header__avatar__container header__avatar__desktop">
                            <img
                                src={HeaderAvatar}
                                alt="header avatar"
                                className="header__avatar"
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section id="brandSection">
                <div className="container mt-5">
                    <div className="row justify-content-around ">
                        <div className="col-12 brand__text mb-4">
                            trusted by thousands of companies across the world
                        </div>
                        <div className="col-4 col-md-3 col-lg-2 ">
                            <img
                                src={Brand1}
                                alt="Brand Logo"
                                className="brand__image "
                            />
                        </div>
                        <div className="col-4 col-md-3 col-lg-2 ">
                            <img
                                src={Brand5}
                                alt="Brand Logo"
                                className="brand__image"
                            />
                        </div>
                        <div className="col-4 col-md-3 col-lg-2 remove__md">
                            <img
                                src={Brand2}
                                alt="Brand Logo"
                                className="brand__image"
                            />
                        </div>

                        <div className="col-4 col-md-3 col-lg-2 remove__md remove__lg">
                            <img
                                src={Brand3}
                                alt="Brand Logo"
                                className="brand__image"
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section id="thirdSection" className="mt-5">
                <div
                    className="container mb-5 third__section px-5"
                    ref={observe}>
                    <div className="row  align-items-center justify-content-around ">
                        <div className="col-12 col-lg-6 margin__custom__1">
                            <div className="third__section__header ">
                                Auto-generate code in seconds
                            </div>
                            <div className="third__section__secondary mt-4 ">
                                No design skill required. Our design algorithm
                                generates on-brand code designs for your next
                                marketing campaign.
                            </div>
                            <div className="third__section__link mt-4 ">
                                Learn more about code generation{' '}
                                <span className="ms-1">
                                    <i className="fas fa-arrow-right third__section__icon"></i>
                                </span>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 margin__custom__1">
                            <img
                                src={ThirdSectionImg}
                                alt=""
                                className="third__section__image"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6 col-lg-3">
                            <div className="third__section__header2">
                                No design skills required
                            </div>
                            <div className="third__section__secondary2">
                                Our algorithm generates stunning & unique codes
                                for your brand in real-time.
                            </div>
                        </div>
                        <div className="col-6 col-lg-3">
                            <div className="third__section__header2">
                                Faster Time-To-Market
                            </div>
                            <div className="third__section__secondary2">
                                Generate codes on the fly whenever you try-out a
                                new acquisition channel.
                            </div>
                        </div>
                        <div className="col-6 col-lg-3">
                            <div className="third__section__header2">
                                Save time & money
                            </div>
                            <div className="third__section__secondary2">
                                HazeGen's streamlined workflow is built to speed
                                up your creation process.
                            </div>
                        </div>
                        <div className="col-6 col-lg-3">
                            <div className="third__section__header2">
                                Built for collaboration
                            </div>
                            <div className="third__section__secondary2">
                                Organize codes into projects, save approved
                                layouts, and share your vision to your team.
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="fourthSection" className="mt-5">
                <div
                    className="container mb-5 fourth__section px-5 "
                    ref={observe2}>
                    <div className="row  align-items-center justify-content-around ">
                        <div className="col-12 col-lg-6 margin__custom__1">
                            <div className="third__section__header ">
                                Design once, reuse infinite times
                            </div>
                            <div className="fourth__section__secondary mt-4">
                                Create smart and scalable templates that’s
                                accessible for your organization and easy for
                                you to manage.
                            </div>
                            <div className="fourth__section__link mt-4">
                                Discover creative automation{' '}
                                <span className="ms-1">
                                    <i className="fas fa-arrow-right third__section__icon"></i>
                                </span>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 margin__custom__1">
                            <img
                                src={FourthSectionImg}
                                alt=""
                                className="third__section__image"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6 col-lg-3">
                            <div className="fourth__section__header2">
                                Production efficiency
                            </div>
                            <div className="fourth__section__secondary2">
                                Select the specific master template & generate
                                creative variants on demand.
                            </div>
                        </div>
                        <div className="col-6 col-lg-3">
                            <div className="fourth__section__header2">
                                Design effortlessly
                            </div>
                            <div className="fourth__section__secondary2">
                                Allow designers to focus on the creative aspects
                                of the work and cut repetitive tasks.
                            </div>
                        </div>
                        <div className="col-6 col-lg-3">
                            <div className="fourth__section__header2">
                                Design automation
                            </div>
                            <div className="fourth__section__secondary2">
                                Built-in design intelligence automates line
                                break and layout management.
                            </div>
                        </div>
                        <div className="col-6 col-lg-3">
                            <div className="fourth__section__header2">
                                Scale across all channels
                            </div>
                            <div className="fourth__section__secondary2">
                                Say good-bye to hours of code resizing for each
                                marketing channel.
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="fifthSection" className="mt-5" ref={observe1}>
                <div className="container mb-5 fifth__section px-5">
                    <div className="row  align-items-center justify-content-around ">
                        <div className="col-12 col-lg-6 margin__custom__1">
                            <div className="fifth__section__header ">
                                RESTful API code generation
                            </div>
                            <div className="fifth__section__secondary mt-4">
                                Unleash your visual marketing power by
                                connecting your apps & getting your apis on the
                                fly.
                            </div>
                            <div className="fifth__section__link mt-4">
                                Explore HazeGen documentation{' '}
                                <span className="ms-1">
                                    <i className="fas fa-arrow-right third__section__icon"></i>
                                </span>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 margin__custom__1">
                            <img
                                src={FifthSectionImg}
                                alt=""
                                className="third__section__image"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6 col-lg-3">
                            <div className="fifth__section__header2">
                                Generation as a Service
                            </div>
                            <div className="fifth__section__secondary2">
                                Powerful synchronous code generation with a
                                simple & extensive API.
                            </div>
                        </div>
                        <div className="col-6 col-lg-3">
                            <div className="fifth__section__header2">
                                Workflow automation
                            </div>
                            <div className="fifth__section__secondary2">
                                Save time and focus on your product by
                                automating visual workflows.
                            </div>
                        </div>
                        <div className="col-6 col-lg-3">
                            <div className="fifth__section__header2">
                                Easy experimentation
                            </div>
                            <div className="fifth__section__secondary2">
                                A/B Test code contents on the fly whether you're
                                on Github, Heroku, AWS…
                            </div>
                        </div>
                        <div className="col-6 col-lg-3">
                            <div className="fifth__section__header2">
                                Zapier integration
                            </div>
                            <div className="fifth__section__secondary2">
                                Easy integration with the most powerful
                                automation platform out there.
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="sixthSection">
                <div className="container">
                    <div className="row ">
                        <div className="col-12">
                            <div className="sixth__section__header mb-2 ">
                                HazeGen gives every team the freedom to create
                            </div>

                            <div className="sixth__section__secondary">
                                {' '}
                                Empower your entire team to create engaging{' '}
                                codes in <br />3 minutes or less.
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="seventhSection">
                <div className="container" ref={observe3}>
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-5 col-xl-3">
                            <div className="seventh__section__icon mb-2">
                                <img src={Ecom} alt="E commerce" />
                            </div>
                            <div className="seventh__section__title mb-3">
                                <span>
                                    <i class="fas fa-circle me-2 seventh__icon1"></i>
                                </span>{' '}
                                For E-Commerce
                            </div>
                            <div className="seventh__section__text">
                                Generate custom codes for your entire product
                                catalog.
                            </div>
                        </div>
                        <div className="col-12 col-md-5 col-xl-3">
                            <div className="seventh__section__icon mb-2">
                                <img src={Agency} alt="E commerce" />
                            </div>
                            <div className="seventh__section__title mb-3">
                                <span>
                                    <i class="fas fa-circle me-2 seventh__icon2"></i>
                                </span>{' '}
                                For Agencies
                            </div>
                            <div className="seventh__section__text">
                                Generate custom codes for your entire product
                                catalog.
                            </div>
                        </div>
                        <div className="col-12 col-md-5 col-xl-3">
                            <div className="seventh__section__icon mb-2">
                                <img src={Sass} alt="E commerce" />
                            </div>
                            <div className="seventh__section__title mb-3">
                                <span>
                                    <i class="fas fa-circle me-2 seventh__icon1"></i>
                                </span>{' '}
                                For Sass
                            </div>
                            <div className="seventh__section__text">
                                Generate custom codes for your entire product
                                catalog.
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <sextion id="eighthSection">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <div className="eighth__section__header mb-3">
                                Trusted by startups and the world’s largest
                                companies
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-xl-6">
                            <div className="eighth__section__box mt-3 mb-5">
                                <div className="container">
                                    <div className="row">
                                        <div className="eighth__section__box__header mb-3">
                                            {' '}
                                            The best tool to bootstrap our Ads
                                            campaigns.
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="eighth__section__box__secondary mb-5">
                                            HazeGen managed to outperform our
                                            expectations while saving money and
                                            time, allowing us to spend our
                                            hiring budget on marketing
                                            campaigns.
                                        </div>
                                    </div>
                                    <div className="row justify-content-between">
                                        <div className="col-7 col-md-8">
                                            <div className="eighth__section__box__name">
                                                Jean de Colombel
                                            </div>
                                            <div className="eighth__section__box__position">
                                                Developer at Pennylane
                                            </div>
                                        </div>
                                        <div className="col-5 col-md-3 eighth__section__box__image ">
                                            <img src={Penny} alt="Penny" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-xl-6">
                            <div className="eighth__section__box extra mt-3 mb-5">
                                <div className="container">
                                    <div className="row">
                                        <div className="eighth__section__box__header mb-3">
                                            {' '}
                                            As a one-man team, time is my
                                            biggest asset.
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="eighth__section__box__secondary mb-5">
                                            HazeGen helps me generate unlimited
                                            customised codes and save about 1
                                            day per week, while increasing my
                                            interaction rate by 40% on average.
                                        </div>
                                    </div>
                                    <div className="row justify-content-between">
                                        <div className="col-7 col-md-8">
                                            <div className="eighth__section__box__name">
                                                Léo Guitton
                                            </div>
                                            <div className="eighth__section__box__position">
                                                Developer at 3DCelo
                                            </div>
                                        </div>
                                        <div className="col-5 col-md-3 eighth__section__box__image ">
                                            <img src={Celo} alt="Celo" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </sextion>
            <section id="ninthSection">
                <div className="container ninth__section mt-5">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                            <div className="price__box ">
                                <div className="row">
                                    <div className="mt-5 price__box__header">
                                        Base
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mt-2  price__box__price">
                                        <span className="price me-2 ">
                                            GHC 200
                                        </span>
                                        /Month
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mt-3 price__box__description">
                                        All the basics to get started
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mt-3 price__box__options">
                                        <span className="me-2">
                                            <i class="fas fa-check-circle"></i>
                                        </span>
                                        1 User
                                    </div>
                                    <div className="mt-3 price__box__options">
                                        <span className="me-2">
                                            <i class="fas fa-check-circle"></i>
                                        </span>
                                        5000 code generations
                                    </div>
                                    <div className="mt-3 price__box__options">
                                        <span className="me-2">
                                            <i class="fas fa-times-circle"></i>
                                        </span>
                                        Teams Feature
                                    </div>
                                    <div className="mt-3 price__box__options">
                                        <span className="me-2">
                                            <i class="fas fa-times-circle"></i>
                                        </span>
                                        Premium support
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="btn btn-large btn-outline-primary price__button mt-5 mb-5">
                                            Choose Plan
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                            <div className="price__box">
                                <div className="row">
                                    <div className="mt-5 price__box__header">
                                        Popular
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mt-2  price__box__price">
                                        <span className="price me-2 ">
                                            GHC 450
                                        </span>
                                        /Month
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mt-3 price__box__description">
                                        Perfect for agile teams
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mt-3 price__box__options">
                                        <span className="me-2">
                                            <i class="fas fa-check-circle"></i>
                                        </span>
                                        5 Users
                                    </div>
                                    <div className="mt-3 price__box__options">
                                        <span className="me-2">
                                            <i class="fas fa-check-circle"></i>
                                        </span>
                                        10000 code generations
                                    </div>
                                    <div className="mt-3 price__box__options">
                                        <span className="me-2">
                                            <i class="fas fa-check-circle"></i>
                                        </span>
                                        Teams Feature
                                    </div>
                                    <div className="mt-3 price__box__options">
                                        <span className="me-2">
                                            <i class="fas fa-times-circle"></i>
                                        </span>
                                        Premium support
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="btn btn-large btn-outline-primary price__button mt-5 mb-5">
                                            Choose Plan
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                            <div className="price__box ">
                                <div className="row">
                                    <div className="mt-5 price__box__header">
                                        Enterprise
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mt-2  price__box__price">
                                        <span className="price me-2 ">
                                            GHC 800
                                        </span>
                                        /Month
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mt-3 price__box__description">
                                        Unleash your creativity
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mt-3 price__box__options">
                                        <span className="me-2">
                                            <i class="fas fa-check-circle"></i>
                                        </span>
                                        unlimited
                                    </div>
                                    <div className="mt-3 price__box__options">
                                        <span className="me-2">
                                            <i class="fas fa-check-circle"></i>
                                        </span>
                                        200000 code generations
                                    </div>
                                    <div className="mt-3 price__box__options">
                                        <span className="me-2">
                                            <i class="fas fa-check-circle"></i>
                                        </span>
                                        Teams Feature
                                    </div>
                                    <div className="mt-3 price__box__options">
                                        <span className="me-2">
                                            <i class="fas fa-check-circle"></i>
                                        </span>
                                        Premium support
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="btn btn-large btn-outline-primary price__button mt-5 mb-5">
                                            Choose Plan
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="tenthSection" className="tenth__Section">
                <div className="container mb-5">
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <div className="trial__section__header">
                                Generate codes in minutes. Try now, it’s free.
                            </div>
                            <div className="trial__section__secondary mt-3">
                                No credit card required - Free trial included
                            </div>
                        </div>
                        <div className="col-12 offset-lg-3 col-lg-3 mt-3">
                            <div>
                                <div className="btn btn-large btn-primary trial__section__button">
                                    Try HazeGen for free
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <footer>
                <div className="container">
                    <div className="row footer__section mb-2">
                        {' '}
                        <div className="follow-icons col-12 col-md-8 col-lg-9 mt-2">
                            <a
                                class="btn btn-outline-primary me-1"
                                href="https://twitter.com/meister_kwame">
                                <i class="fab fa-fw fa-twitter"></i>
                            </a>

                            <a
                                class="btn btn-outline-primary me-1"
                                href="https://github.com/frederickhazel6996?tab=repositories">
                                <i class="fab fa-fw fa-github"></i>
                            </a>
                        </div>
                        <div className="col-12 col-md-4 col-lg-3 mt-2 myname">
                            &copy;{' '}
                            <em id="date">
                                2021 Meister Kwame{' '}
                                <span>
                                    <img
                                        src={Ghana}
                                        alt="Ghana Flag"
                                        className="ghana__flag"
                                    />
                                </span>
                            </em>
                        </div>
                    </div>
                </div>
            </footer>
            <a
                id="back-to-top"
                href="#"
                class="btn btn-light btn-lg back-to-top"
                role="button">
                <i class="fas fa-chevron-up "></i>
            </a>
        </>
    );
};

export default Home;
