import React, { useEffect } from 'react';
import { NavbarIcon } from '../../Utils/values';
import { scrollProgressBar } from '../../Utils/methods';
import './index.scss';

const Navbar = () => {
    useEffect(() => {
        scrollProgressBar();
    }, []);
    return (
        <>
            <div className="fixed-top">
                <header className="nav__header">
                    <div className="container ">
                        <nav className="navbar  navbar-expand-lg ">
                            <div className="container-fluid">
                                <a className="navbar-brand" href="#">
                                    <img
                                        src={NavbarIcon}
                                        alt="Navbar Icon"
                                        className="navbar__logo"
                                    />
                                </a>
                                <button
                                    className="navbar-toggler"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#navbarSupportedContent"
                                    aria-controls="navbarSupportedContent"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon">
                                        <i class="fas fa-bars mt-2"></i>
                                    </span>
                                </button>
                                <div
                                    className="collapse navbar-collapse"
                                    id="navbarSupportedContent">
                                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                        <li className="nav-item dropdown">
                                            <a
                                                className="nav-link "
                                                href="#"
                                                id="navbarDropdown"
                                                role="button"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false">
                                                Product
                                                <i className="fas fa-chevron-down ms-1 nav__icon"></i>
                                            </a>
                                            <ul
                                                className="dropdown-menu "
                                                aria-labelledby="navbarDropdown">
                                                <a
                                                    href="#"
                                                    className="nav__drop__links">
                                                    <div className="container mb-4">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="row">
                                                                    <div className="col-2">
                                                                        <i class="fab fa-elementor nav__drop__icon"></i>
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <div className="row">
                                                                            <div className="col-12 nav__drop__header">
                                                                                Auto-Generate
                                                                            </div>
                                                                            <div className="col-12 nav__drop__text">
                                                                                Make
                                                                                on-brand
                                                                                banners
                                                                                in
                                                                                seconds
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                                <a
                                                    href="#"
                                                    className="nav__drop__links">
                                                    <div className="container mb-2">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="row">
                                                                    <div className="col-2">
                                                                        <i class="fas fa-users nav__drop__icon"></i>
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <div className="row">
                                                                            <div className="col-12 nav__drop__header">
                                                                                For
                                                                                teams
                                                                            </div>
                                                                            <div className="col-12 nav__drop__text">
                                                                                Creative
                                                                                collaboration
                                                                                with
                                                                                ease
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </ul>
                                        </li>
                                        <li className="nav-item">
                                            <a
                                                className="nav-link"
                                                href="#ninthSection">
                                                Pricing
                                            </a>
                                        </li>
                                    </ul>

                                    <div className="mobile-buttons">
                                        <button
                                            className="btn btn-lg btn-outline-primary nav__button me-3"
                                            type="submit">
                                            Sign In
                                        </button>
                                        <button
                                            className="btn btn-lg btn-primary nav__button"
                                            type="submit">
                                            Try for free
                                        </button>
                                    </div>
                                    <div className="desktop-buttons">
                                        <button
                                            className="btn btn-lg btn-light nav__button2 me-3"
                                            type="submit">
                                            Sign In
                                        </button>
                                        <button
                                            className="btn btn-lg btn-outline-primary nav__button"
                                            type="submit">
                                            Try for free
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </header>
                <div class="progress">
                    <div
                        class="progress-bar navbar__progress"
                        role="progressbar"
                        style={{ width: 0 }}
                        aria-valuenow="0"
                        aria-valuemin="0"
                        aria-valuemax="100"></div>
                </div>
            </div>
            <div className="nav__spacer"></div>
        </>
    );
};

export default Navbar;
