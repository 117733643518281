export const NavbarIcon = require('../Assets/Images/hazel.svg').default;
export const HeaderAvatar =
    require('../Assets/Images/headerImage1.svg').default;
export const HeaderAvatarMobile =
    require('../Assets/Images/headerImageMobile1.svg').default;
export const Brand1 = require('../Assets/Images/brand1.svg').default;
export const Brand2 = require('../Assets/Images/brand2.svg').default;
export const Brand3 = require('../Assets/Images/brand3.svg').default;
export const Brand4 = require('../Assets/Images/brand4.svg').default;
export const Brand5 = require('../Assets/Images/brand5.svg').default;
export const Sass = require('../Assets/Images/sass.svg').default;
export const Ecom = require('../Assets/Images/ecom.svg').default;
export const Agency = require('../Assets/Images/agency.svg').default;
export const Penny = require('../Assets/Images/penny.svg').default;
export const Ghana = require('../Assets/Images/ghana.svg').default;
export const Celo = require('../Assets/Images/celo.svg').default;
export const ThirdSectionImg =
    require('../Assets/Images/third_section.webp').default;
export const FourthSectionImg =
    require('../Assets/Images/fourth_section.webp').default;
export const FifthSectionImg =
    require('../Assets/Images/fifth_section.webp').default;
